<template>
    <div class="l-inline l-center login-form-wrapper topography-background">
        <div class="card">
            <form
                class="l-padded l-stack l-gap-2"
                @submit.prevent="handleSubmit"
            >
                <div class="l-inline l-justify-end">
                    <img :src="logo()" alt="Logo" height="40" />
                </div>

                <template v-if="!loginSucceed">
                    <h1>{{ $t('title') }}</h1>

                    <h2>{{ $t('subtitle') }}</h2>

                    <span v-if="loginFailed" class="login-form-error">
                        {{ $t('loginFailed') }}
                    </span>

                    <form-input
                        v-model="$v.username.$model"
                        v-focus
                        :label="$t('username')"
                        :show-required-error="
                            $v.username.$dirty && !$v.username.required
                        "
                        data-test-username
                        required
                    />

                    <form-input
                        v-model="$v.password.$model"
                        type="password"
                        :label="$t('password')"
                        :show-required-error="
                            $v.password.$dirty && !$v.password.required
                        "
                        data-test-password
                        required
                    />
                </template>

                <template v-else>
                    <div>
                        <p class="form-label">
                            {{ $t('simulateTip') }}
                        </p>

                        <user-select
                            v-model="userToSimulate"
                            :exclude="[getCurrentUserId]"
                            show-details
                        ></user-select>
                    </div>
                </template>

                <div
                    class="l-inline l-gap-2 l-center-v l-justify-end login-form-actions"
                >
                    <v-spinner
                        v-show="isLoading"
                        :size="24"
                        :speed="1"
                        line-fg-color="black"
                        line-bg-color="transparent"
                    />

                    <ax-button :disabled="isLoading">
                        <template v-if="!loginSucceed">
                            {{ $t('login') }}
                        </template>

                        <template v-else-if="!userToSimulate">
                            {{ $t('skip') }}
                        </template>

                        <template v-else>
                            {{ $t('simulate') }}
                        </template>
                    </ax-button>
                </div>
            </form>
            <div
                v-if="!!linkToPlatformWebsite"
                class="more-information l-padded"
            >
                <h3>{{ $t('noLoginYet') }}</h3>
                <p>
                    <a :href="linkToPlatformWebsite">{{
                        $t('morInformation')
                    }}</a>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import VSpinner from 'vue-simple-spinner'

import { languageHelper } from '@/utils'
import AssetHelpers from '@/mixins/AssetHelpers'

import { commonService } from '../service/store.service'
import AxButton from '../components/AxButton'
import FormInput from '../components/FormInput'
import UserSelect from '../components/UserSelect'

export default {
    name: 'PublicLayout',
    components: {
        AxButton,
        FormInput,
        UserSelect,
        VSpinner,
    },
    mixins: [AssetHelpers],
    props: {
        nextUrl: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            username: '',
            password: '',
            isLoading: false,
            loginFailed: false,
            loginSucceed: false,
            userToSimulate: null,

            linkToPlatformWebsite: process.env.VUE_APP_PLATFORM_WEBSITE_LINK,
        }
    },
    computed: {
        ...mapGetters('authentication', [
            'getCurrentUserId',
            'getJWT',
            'isStaff',
        ]),
    },
    methods: {
        ...mapActions('authentication', [
            'loadUserInfo',
            'login',
            'simulateUser',
        ]),
        ...mapActions('common', ['loadData']),
        async handleSubmit() {
            if (this.loginSucceed) {
                if (this.userToSimulate) {
                    await this.simulateUser(this.userToSimulate)
                }

                this.redirect()
                return
            }

            this.loginFailed = false
            if (!this.isValid()) {
                return
            }

            try {
                this.isLoading = true
                await this.login({
                    username: this.username,
                    password: this.password,
                })
            } catch {
                this.isLoading = false
                this.loginFailed = true
                return
            }

            await this.loadUserInfo()
            this.$root.$i18n.locale = languageHelper.getUsersLanguage()

            if (this.isStaff) {
                await commonService.populateUsers()
                this.isLoading = false
                this.loginSucceed = true
            } else {
                this.isLoading = false
                this.redirect()
            }
        },
        isValid() {
            this.$v.$touch()
            return !this.$v.$invalid
        },
        redirect() {
            this.$router.push(this.nextUrl || '/', this.loadData)
        },
    },
    validations() {
        return {
            username: {
                required,
            },
            password: {
                required,
            },
        }
    },
}
</script>

<i18n>
{
    "en": {
        "login": "Login",
        "loginFailed": "No user for this username password combination found.",
        "password": "Password",
        "simulate": "Simulate",
        "simulateTip": "Select user to simulate",
        "skip": "Skip",
        "subtitle": "Please log in.",
        "title": "Welcome.",
        "username": "Username",
        "noLoginYet": "No login yet?",
        "morInformation": "More information about the platform is available here."
    },
    "de": {
        "login": "Einloggen",
        "loginFailed": "Keinen Benutzer für diese Kombination von Benutzernamen und Passwort gefunden.",
        "password": "Passwort",
        "simulate": "Simulieren",
        "simulateTip": "Benutzer für Simulation auswählen",
        "skip": "Überspringen",
        "subtitle": "Bitte melden Sie sich an.",
        "title": "Willkommen.",
        "username": "Benutzername",
        "noLoginYet": "Noch kein Login?",
        "morInformation": "Mehr Informationen zur Plattform gibt es hier."
    },
    "it": {
        "login": "Login",
        "loginFailed": "Login non valido.",
        "password": "Password",
        "simulate": "Simulate",
        "simulateTip": "Selezionare l'utente da simulare",
        "skip": "Salta",
        "title": "Benvenuto.",
        "subtitle": "Effettua il login.",
        "username": "Nome utente",
        "noLoginYet": "Non sei ancora registrato?",
        "morInformation": "Ulteriori informazioni sulla piattaforma sono disponibili qui."
    }
}
</i18n>

<style lang="scss" scoped>
.card {
    margin: 1rem;
    width: 375px;
    border-left: $style-border;
    border-radius: 8px;
    background-color: white;
    box-shadow: -1px 0px 1px rgba(0, 0, 0, 0.04),
        -3px 20px 80px rgba(0, 0, 0, 0.3);
}

.login-form-wrapper {
    width: 100%;
    height: 100%;
    background-color: $color-gray-light;
}

.login-form-error {
    margin-bottom: 0.5rem;
    font-size: $fs-label;
    color: red;
}

.login-form-actions {
    & > * {
        width: auto;
    }
}

.more-information {
    border-top: $style-border;

    a {
        text-decoration: underline;
    }
    p {
        padding-top: 0.5rem;
    }
}
</style>
